<template>
  <v-container fluid>
    <v-card tile flat>
      <skeleton-page-heading v-if="loading" />
      <v-card-text v-else>
        <breadcrumb-component />
        <v-divider class="tw-mb-5"></v-divider>
        <action-bar
          :show="true"
          :refresh="refresh"
          :loading="loading"
          :search="search"
          :search-data="searchData"
          :route-create="routeCreate"
        />
        <v-divider class="tw-mt-5"></v-divider>
      </v-card-text>
      <v-card-text>
        <skeleton-table v-if="loading" />
        <server-side-table
          v-else
          :headers="role === 2 ? developerHeaders : headers"
          :footers="footers"
          :total-items="totalItems"
          :items="items"
          :loading="loading"
          :update-item-per-page="updateItemPerPage"
          :get-data-from-api="getDataFromApi"
          :remove-item="removeItem"
          :from="from ? from : 0"
          :total="total"
          :page="page"
          :route-edit="routeEdit"
          :action-show="true"
          :action-delete="true"
          :action-edit="true"
          :action-detail="false"
          :action-status="true"
          :action-approval="true"
          :action-brochure="true"
          :approved="approvedFunction"
          :rejected="rejectedFunction"
        />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import mixin from "@/mixins/mixin";
import { approved, rejected } from "@/api/propertyDetail";
import BreadcrumbComponent from "../components/BreadcrumbComponent.vue";
import ActionBar from "../components/ActionBar.vue";
import ServerSideTable from "../components/ServerSideTable.vue";
import SkeletonTable from "../components/SkeletonTable.vue";
import SkeletonPageHeading from "../components/SkeletonPageHeading.vue";

export default {
  components: {
    BreadcrumbComponent,
    ActionBar,
    ServerSideTable,
    SkeletonTable,
    SkeletonPageHeading,
  },
  mixins: [mixin],
  data: () => ({
    url: "property-details",
    routeEdit: "property-detail-edit",
    routeCreate: "property-detail-create",
    headers: [
      { text: "#", value: "order" },
      { text: "Property Name", value: "property_name" },
      { text: "Project Name", value: "project.name" },
      { text: "Vendor Name", value: "vendor.user.name" },
      { text: "Harga", value: "price" },
      { text: "Bedroom", value: "bedroom", align: 'center' },
      { text: "Bathroom", value: "bathroom", align: 'center' },
      { text: "Status", value: 'status', align: 'center' },
      { text: "Action", value: "actions" },
      { text: "Approval", value: 'approvals', align: 'center' },
    ],
    developerHeaders: [
      { text: "#", value: "order" },
      { text: "Property Name", value: "property_name" },
      { text: "Project Name", value: "project.name" },
      { text: "Vendor Name", value: "vendor.user.name" },
      { text: "Harga", value: "price" },
      { text: "Bedroom", value: "bedroom", align: 'center' },
      { text: "Bathroom", value: "bathroom", align: 'center' },
      { text: "Brosur", value: "brochure" },
      { text: "Status", value: 'status', align: 'center' },
      { text: "Action", value: "actions" },
    ],
  }),
  methods: {
    async approvedFunction(id) {
      await approved(id)
    },
    async rejectedFunction(id) {
      await rejected(id)
    }
  },
  computed: {
    role() {
      return parseInt(this.$store.state.auth.profile.role_id);
    },
  },
};
</script>

<style></style>
